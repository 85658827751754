<template>
  <div style="text-align: left">

    <article>
      <section>
        <div class="safari_dialogs">
          <el-dialog
            :title="`Edit Safari Type`"
            :visible.sync="isEditPackageDialogVisible"
            :destroy-on-close="true"
            :before-close="handleClose"
            style="text-align: left"
          >
            <div v-loading="isUploadingFile">
              <el-form
                :model="safariTypeForm"
                :rules="packageRules"
                ref="safariTypeForm"
                class="demo-safariTypeForm2"
              >
                <div class="row">
                  <div class="col-md-12">
                    <label>Title</label>
                    <el-form-item prop="title">
                      <el-input v-model="safariTypeForm.title"></el-input>
                    </el-form-item>
                  </div>
                 
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <!-- <label>Description</label>
                    <el-form-item prop="title">
                      <el-input v-model="safariTypeForm.description"></el-input>
                    </el-form-item> -->

                    <label class="mt-3 mb-2">Content</label><br />
                   <div id="editor" ref="editor" ></div>
                  </div>

                </div>

                <hr />
                <el-form-item class="text-center">
                  <el-button
                    type="primary"
                    @click="editsafariType('safariTypeForm')"
                  >Edit Safari Type</el-button>
                </el-form-item>
              </el-form>

              <div class="text-center">
                <div class="text-center">
                  <small>© 2024 Tata Africa Safaris | All rights reserved.</small>
                </div>
              </div>
            </div>
          </el-dialog>
        </div>
      </section>
    </article>
  </div>
</template>


<script>
export default {
  data() {
    return {
      quill:null,
      addPackageModal: false,
      content: "",
      selectedFile: null,
      isUploadingFile: false,
      value: "",
      loading: false,
      loadingError: false,
      imageUploadingProgress: 0,
      progressColors: [
        { color: "#f56c6c", percentage: 25 },
        { color: "#e6a23c", percentage: 50 },
        { color: "#1989fa", percentage: 75 },
        { color: "#6f7ad3", percentage: 100 },
      ],
      safariTypeForm: {
        title: "",
        description: "",
      },
      packageRules: {
        title: [
          {
            required: true,
            message: "Title is required",
            trigger: "blur",
          },
          {
            required: true,
            message: "Description is required",
            trigger: "blur",
          },
        ],
      },
    };
  },

  props: {
    isEditPackageDialogVisible: {
      required: true,
      type: Boolean,
    },
    safariType: {
      required: true,
      type: Object,
    },
  },

  mounted(){

    const toolbarOptions = 
        [  
          
  ['bold', 'italic', 'underline', 'strike'],   // toggled buttons
  ['blockquote', 'code-block'],
  [{'header': 1}, {'header': 2}],              // custom button values
  [{'list': 'ordered'}, {'list': 'bullet'}],
  [{'script': 'sub'}, {'script': 'super'}],     // superscript/subscript
  [{'indent': '-1'}, {'indent': '+1'}],         // outdent/indent
  [{'direction': 'rtl'}],                       // text direction

  [{'size': ['small', false, 'large', 'huge']}], // custom dropdown
  [{'header': [1, 2, 3, 4, 5, 6, false]}],

  [{'color': []}, {'background': []}],          // dropdown with defaults from theme
  [{'font': []}],
 
  [{'align': []}],
 
  ['clean'],   
  ['link', 'image', 'video'] 
                                  // remove formatting button
  
    ];

    this.quill = new Quill('#editor', {
      modules: {
     
        imageResize: {
          displaySize: true
        },
     
        toolbar: toolbarOptions, 
       
      },
      theme: 'snow',
      placeholder: "Detailed blog content"
    });
    // listen for image uploads
    this.quill.getModule('toolbar').handlers['image'] = this.imageHandler;

    this.quill.on('text-change', () => {
      this.content = this.quill.root.innerHTML
      
    })

    

  },

  watch: {
    isEditPackageDialogVisible() {
      if (this.isEditPackageDialogVisible) {
        this.safariTypeForm.title = this.safariType.title;
        // this.safariTypeForm.description = this.safariType.description;
        this.quill.root.innerHTML = this.safariType.description;

      }
    },
  },

 

  methods: {

    
    //
    handleClose(done) {
      done();
      this.$emit("closeEditPackageDialog");
    },

    handlePreview(file) {
      console.log(file.raw);
      this.fileLists = [file];
      this.selectedFile = file.raw;
    },
    handleRemove() {
      this.selectedFile = null;
    },

    async editsafariType(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          try {
            this.isUploadingFile = true;
            let request = await this.$http.post(
              `api/safari_types/edit/${this.safariType.id}`,
              {
                title: this.safariTypeForm.title,

                description: this.safariTypeForm.description,
              }
            );
            if (
              request.data.success &&
              request.data.message == "EDITED_SUCCESSFULLY"
            ) {
              this.$emit("refreshTypes");
              this.$notify({
                title: "Success",
                message: "Edited successfully",
                type: "success",
              });
            } else throw "UNEXPECTED_RESPONSE";
          } catch (error) {
            if (error.message == "NetworkError") {
              this.isUploadingFile = false;
              return this.$notify({
                title: "Connection failed",
                message: "A network error occurred please try again",
                type: "warning",
              });
            }
            this.isUploadingFile = false;
            this.$notify({
              title: "Upload Failed",
              message: "Unable to edit safari type now, Please try again",
              type: "error",
            });
          } finally {
            this.isUploadingFile = false;
          }
        } else {
          return false;
        }
      });
    },

    showAddPackageModal() {
      this.addPackageModal = true;
    },
  },
};
</script>

<style scoped>
input[type="text"],
select,
textarea {
  width: 100%;
  padding: 10px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  background-color: #ffffff;
  font-size: 0.9em;
}

.search_add_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search_by_input {
  width: 400px !important;
}

/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .search_add_section {
    display: block;
  }
  .addbtn {
    width: 100%;
    margin-top: 10px;
  }
  .search_by_input {
    width: 100% !important;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (min-width: 320px) and (max-width: 480px) {
  .search_add_section {
    display: block;
  }
  .addbtn {
    width: 100%;
    margin-top: 10px;
  }
  .search_by_input {
    width: 100% !important;
  }
}
</style>

<style>
.safari_dialogs .el-dialog {
  width: 60%;
}

/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
  .safari_dialogs .el-dialog {
    width: 60%;
  }
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .safari_dialogs .el-dialog {
    width: 70%;
  }
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .safari_dialogs .el-dialog {
    width: 90%;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (min-width: 320px) and (max-width: 480px) {
  .safari_dialogs .el-dialog {
    width: 90%;
  }
}
</style>
